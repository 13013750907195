import React from "react";
import { Link } from "gatsby";
import logo from "../img/logo.svg";
import { Navbar, Container } from "rbx";

const Nav = class extends React.Component {
  render() {
    return (
      <Container>
        <Navbar>
          <Navbar.Brand>
            <Navbar.Item as={Link} to="/">
              <img
                style={{
                  maxHeight: "3rem",
                  margin: "0 0.25em 0 0"
                }}
                src={"/img/TV_GW.png"}
                alt=""
              />
              <strong className="nav-brand-name has-text-primary">
                Tennisverein Großweikersdorf
              </strong>
            </Navbar.Item>
            <Navbar.Burger />
          </Navbar.Brand>
          <Navbar.Menu>
            <Navbar.Segment align="start">
              <Navbar.Item as={Link} to="/info">
                Tennis spielen
              </Navbar.Item>
              <Navbar.Item as={Link} to="/neuigkeiten">
                Neuigkeiten
              </Navbar.Item>
              <Navbar.Item as={Link} to="/jugend">
                Jugend
              </Navbar.Item>
              <Navbar.Item as={Link} to="/about">
                Über uns
              </Navbar.Item>
              <Navbar.Item as={Link} to="/kontakt">
                Kontakt
              </Navbar.Item>
              <Navbar.Item dropdown>
                <Navbar.Link>Mehr</Navbar.Link>
                <Navbar.Dropdown>
                  <Navbar.Item as={Link} to="/impressum">
                    Impressum
                  </Navbar.Item>
                  <Navbar.Item as={Link} to="/datenschutz">
                    Datenschutz
                  </Navbar.Item>
                  <Navbar.Item as={Link} to="/platzordnung">
                    Platzordnung
                  </Navbar.Item>
                </Navbar.Dropdown>
              </Navbar.Item>
            </Navbar.Segment>
          </Navbar.Menu>
        </Navbar>
      </Container>
    );
  }
};

export default Nav;
