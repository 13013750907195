import React from "react";
import { Link } from "gatsby";
import { Footer, Level, Menu, Container, Title, Icon } from "rbx";
const TVFooter = () => (
  <Footer>
    <Container>
      <Level>
        <Level.Item align="left">
          <img style={{ maxHeight: "8rem" }} src={"/img/TV_GW.png"} alt="" />
        </Level.Item>
        <Level.Item align="right">
          <Menu>
            <Menu.List>
              <Menu.List.Item as={Link} to="/impressum">
                <Icon>
                  <i class="fas fa-info" />
                </Icon>{" "}
                Impressum
              </Menu.List.Item>
              <Menu.List.Item as={Link} to="/datenschutz">
                <Icon>
                  <i class="fas fa-shield-alt" />
                </Icon>{" "}
                Datenschutz
              </Menu.List.Item>
              <Menu.List.Item as={Link} to="/kontakt">
                <Icon>
                  <i class="fas fa-envelope" />
                </Icon>{" "}
                Kontakt
              </Menu.List.Item>
              <Menu.List.Item
                as={"a"}
                href="https://www.facebook.com/TennisvereinGrossweikersdorf/"
              >
                <Icon>
                  <i class="fab fa-facebook" />
                </Icon>{" "}
                Facebook
              </Menu.List.Item>
            </Menu.List>
          </Menu>
        </Level.Item>
      </Level>
    </Container>
  </Footer>
);

export default TVFooter;
